import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "../../Components/Header/header";
import Home from "../Home/Home";
import { Grid } from "@mui/material";

export default function App(this: any) {
  const [isDevelop, setIsDevelop] = useState(false);
  const demo: boolean = process.env.REACT_APP_IS_DEMO === "true" ? true : false;

  const switchBetweenEnv = () => {
    if (isDevelop) {
      setIsDevelop(false);
    } else {
      setIsDevelop(true);
    }
  };

  return (
    <Grid container width="100vw">
      <Router>
        <Header switchBetweenEnv={switchBetweenEnv} isDemo={demo} />
        <Routes>
          <Route path="/" element={<Home isDevelop={isDevelop} isDemo={demo} />} />
          <Route
            path="/develop"
            element={<Home isDevelop={isDevelop} isDemo={demo} />}
          />
        </Routes>
      </Router>
    </Grid>
  );
}
