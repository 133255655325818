import React, { FC } from "react";
import DeveloperBoardIcon from "@mui/icons-material/DeveloperBoard";
import Logo from "../../assets/logo.png";
import ThalesLogo from "../../assets/ThalesLogo.png";
import { Link } from "react-router-dom";
import "./header.css";

interface HeaderProps {
  switchBetweenEnv?: () => void;
  isDemo: boolean;
}

const Header: FC<HeaderProps> = ({ switchBetweenEnv, isDemo }) => {
  const isProduction: boolean =
    process.env.REACT_APP_IS_PRODUCTION === "true" ? true : false;

  return (
    <div id="header">
      <div id="header-top">
        <div id="top-left">
          <div id="logo-top">
            {isDemo ? (
              <img id="logo-top-image" src={ThalesLogo} alt=""></img>
            ) : (
              <img id="logo-top-image" src={Logo} alt=""></img>
            )}
          </div>
        </div>
        {isDemo ? (
          <></>
        ) : (
          <div id="top-right">
            {isProduction ? (
              <></>
            ) : (
              <Link to="/develop" onClick={switchBetweenEnv}>
                <DeveloperBoardIcon id="settings" />
              </Link>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
