import React, { FC } from "react";

import "./Webchat.css";

interface WebchatProps {
  serviceName: string;
  webchatUrl: string;
}

const Webchat: FC<WebchatProps> = ({ serviceName, webchatUrl }) => {
  const orgName = process.env.REACT_APP_ORGANIZATION_NAME as string;
  // this will change to serve multiple parameters to the webchat iframe for instance primary and secondary colors and themes etc.
  const sourceUrl = `${webchatUrl}/?serviceName=${serviceName}&${
    orgName !== null ? `organization=${orgName}` : null
  }`;

  return (
    <div id="webchat-container">
      <iframe
        title="generic-webchat"
        src={sourceUrl}
        width="100%"
        height="100%"
        style={{ border: "none" }}
      ></iframe>
    </div>
  );
};

export default Webchat;
