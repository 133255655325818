import React, { FC } from "react";
import "./Home.css";
import Webchat from "../../Components/Webchat/Webchat";
import Gwenda from "../../assets/gwenda.png";
import ThalesHeroBanner from "../../assets/ThalesHeroBanner.png";
import Bubble from "../../assets/bubble-diagram.png";
import { Grid } from "@mui/material";

interface HomeProps {
  isDevelop: boolean;
  isDemo: boolean;
}

const Home: FC<HomeProps> = ({ isDevelop, isDemo }) => {
  const isProduction: boolean =
    process.env.REACT_APP_IS_PRODUCTION === "true" ? true : false;

  return (
    <>
      {isDemo ? (
        <>
          <Grid item xs={12} sx={{ objectFit: "contain", paddingTop: "130px" }}>
            <img
              alt="ClientHeroBanner"
              src={ThalesHeroBanner}
              width="100%"
            ></img>
          </Grid>
          <Webchat
            serviceName={
              isProduction
                ? (process.env.REACT_APP_PRIMARY_SERVICENAME as string)
                : isDevelop
                ? (process.env.REACT_APP_SECONDARY_SERVICENAME as string)
                : (process.env.REACT_APP_PRIMARY_SERVICENAME as string)
            }
            webchatUrl={
              isProduction
                ? (process.env.REACT_APP_PRIMARY_WEBCHAT_FRONTEND_URL as string)
                : isDevelop
                ? (process.env
                    .REACT_APP_SECONDARY_WEBCHAT_FRONTEND_URL as string)
                : (process.env.REACT_APP_PRIMARY_WEBCHAT_FRONTEND_URL as string)
            }
          />
        </>
      ) : (
        <div id="home">
          <div id="page">
            <div id="page-wrap">
              <div id="image-fullwidth-bottom" />
              <div id="panel" className="topPannelTitle">
                <div className="titlePanelText">
                  <h1 id="panel-title">Demonstrator</h1>
                  <p id="panel-text">ITSM Chatbot</p>
                </div>
                <img id="bubbleimg" alt="Bubble" src={Bubble} />
              </div>
              <div id="page-column-wrapper">
                <div id="page-left-column">
                  <div id="about">
                    <p id="about-text">
                      Hallo, ik ben Gwenda, de Conversational Artificial
                      Intelligence van CIMSOLUTIONS. Ik heb al ruim 30 jaar
                      ervaring in de ICT. Niet omdat ik al zo oud ben, maar wel
                      omdat ik ben ontwikkeld door een team van hoogwaardige ICT
                      professionals uit een breed scala aan expertisegebieden.
                      Daarnaast ben ik erg leergierig en maak me snel nieuwe
                      dingen eigen in de context van uw organisatie. Dit komt
                      doordat ik beschik over de laatste conversational
                      artificial intelligence technieken. Ik ben een grote
                      aanwinst voor uw organisatie, omdat ik van grote
                      toegevoegde waarde ben in goede heldere communicatie
                      vanuit de ICT afdeling naar de gebruikersorganisatie en
                      visa versa. Uiteraard begrijp ik dat u misschien moet
                      wennen aan het gebruik van conversational artificial
                      intelligence. Daarom introduceer ik u graag, door middel
                      van deze demo-omgeving, een aantal van mijn expertises die
                      bij uw organisatie kunnen worden ingezet. Vriendelijke
                      groet, Gwenda en CIMSOLUTIONS Conversational A.I. Team.
                    </p>
                  </div>
                </div>
                <div id="page-right-column">
                  <div id="page-deco-vid">
                    <img id="deco-video" alt="Deco" src={Gwenda} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Webchat
            serviceName={
              isProduction
                ? (process.env.REACT_APP_PRIMARY_SERVICENAME as string)
                : isDevelop
                ? (process.env.REACT_APP_SECONDARY_SERVICENAME as string)
                : (process.env.REACT_APP_PRIMARY_SERVICENAME as string)
            }
            webchatUrl={
              isProduction
                ? (process.env.REACT_APP_PRIMARY_WEBCHAT_FRONTEND_URL as string)
                : isDevelop
                ? (process.env
                    .REACT_APP_SECONDARY_WEBCHAT_FRONTEND_URL as string)
                : (process.env.REACT_APP_PRIMARY_WEBCHAT_FRONTEND_URL as string)
            }
          />
        </div>
      )}
    </>
  );
};

export default Home;
